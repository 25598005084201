import { useState } from 'react'

import { TextField } from '@mui/material'
import {
  BaserowPasswordByUserId,
  BaserowPasswordByUserIdVariables,
} from 'types/graphql'

import { Metadata, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import Tab from 'src/components/Library/Tab'
import Tabs from 'src/components/Library/Tabs'
import PageHeader from 'src/components/PageHeader/PageHeader'
import { useAuth } from 'src/Providers'

enum BR_TOOLS {
  ACCOUNTS = 'accounts',
}

const BaserowToolsPage = () => {
  const { currentUser } = useAuth()

  const [activeTab, setActiveTab] = useState(BR_TOOLS.ACCOUNTS)
  const [userId, setUserId] = useState('')
  const [processing, setProcessing] = useState(false)
  const [skip, setSkip] = useState(true)

  const BASEROW_PASSWORD_BY_USER_ID = gql`
    query BaserowPasswordByUserId($userId: Int!) {
      baserowPasswordByUserId(userId: $userId)
    }
  `

  const { data } = useQuery<
    BaserowPasswordByUserId,
    BaserowPasswordByUserIdVariables
  >(BASEROW_PASSWORD_BY_USER_ID, {
    variables: { userId: Number(userId) },
    skip,
    onCompleted: () => {
      setProcessing(false)
      setSkip(false)
    },
    onError: (error) => {
      setProcessing(false)
      toast.error(error.message)
    },
  })

  return (
    <>
      <Metadata title="Baserow Tools" description="BaserowTools page" />

      <PageHeader
        title={'Baserow Tools'}
        parentDataTestId="baserow-tools-page-header"
      />
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => {
          if (newValue) {
            setActiveTab(String(newValue) as BR_TOOLS)
          }
        }}
      >
        <Tab label="Accounts" value="accounts"></Tab>
      </Tabs>
      <div className="p-4">
        {activeTab === BR_TOOLS.ACCOUNTS && (
          <div className="flex flex-col bg-white border-gray-200 border-2 rounded-lg p-2">
            <div className="flex flex-col gap-4">
              <p>Get Baserow Password for User</p>
              <div className="flex gap-2">
                <TextField
                  id="user-id"
                  label="User ID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  size="small"
                />
                <Button
                  className="w-48"
                  loading={processing}
                  disabled={userId === ''}
                  onClick={() => {
                    setProcessing(true)
                    setSkip(false)
                  }}
                >
                  Get Password
                </Button>
              </div>
              <div className="flex flex-col gap-2">
                <p>Get My Baserow Password</p>
                <Button
                  className="w-48"
                  loading={processing}
                  onClick={() => {
                    setUserId(currentUser.userData.id.toString())
                    setProcessing(true)
                    setSkip(false)
                  }}
                >
                  Get My Password
                </Button>
              </div>
              {data && (
                <div>
                  <p>Password: {data?.baserowPasswordByUserId}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BaserowToolsPage
